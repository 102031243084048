import styled from '@xstyled/styled-components'
import { Link } from '@reach/router'

export const Title = styled.h1`
  margin-bottom: 44px;
`

export const List = styled.ul`
  list-style: none;
  /* overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 335px); */
  li {
    padding-bottom: 24px;
  }
`
export const ListContainer = styled.div`
  /* overflow: hidden; */
  height: calc(100vh - 335px);
  div {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: black;
    }
  }
`
export const Bar = styled.div`
  border-bottom: solid 1px #fff;
  margin: 0 0 12px 8px;
`

export const Li = styled.li`
  display: flex;
  flex-direction: row;
`

export const ModuleLink = styled(Link)`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #929292;
  &:hover {
    color: #fff;
  }
`
