import React, { useRef } from 'react'
import { List, ModuleLink, Title, Bar, Li, ListContainer } from './styled'
import gsap from 'gsap'
import FlexVerticalDrag from '@/components/Draggers/FlexVerticalDrag'

const createModuleUrl = ({ slug }) => `/our-platform/module/${slug}`

const ModuleList: React.FC<Props> = ({ data }) => {
  const listRef = useRef(null)
  const tls = {}
  const time = 0.4
  const containerRef = useRef(null)

  const findComponent = (url) => {
    const nodes = listRef.current.childNodes

    let selected = -1
    nodes.forEach((element, index) => {
      if (element.id === url) {
        selected = index
      }
    })

    if (selected === -1) return null

    return nodes[selected]
  }

  const hoverStart = (url) => {
    const node = findComponent(url)

    const totalWidth = node.clientWidth
    const textWidth = node.childNodes[0].clientWidth + 16

    const bar = node.childNodes[1]

    if (tls[url] === undefined) {
      tls[url] = gsap.timeline()
    } else {
      tls[url].clear()
    }

    tls[url].fromTo(
      bar,
      time,
      {
        width: 0,
      },
      {
        width: totalWidth - textWidth,
      },
    )
  }

  const hoverExit = (url) => {
    const node = findComponent(url)
    const bar = node.childNodes[1]

    if (tls[url] === undefined) {
      tls[url] = gsap.timeline()
    } else {
      tls[url].clear()
    }

    tls[url].to(bar, time, {
      width: 0,
    })
  }

  return (
    <>
      <Title>Modules</Title>
      <ListContainer ref={containerRef}>
        <FlexVerticalDrag parentRef={containerRef}>
          <List ref={listRef}>
            {data.map((node) => (
              <Li key={node.id} id={node.id}>
                <ModuleLink
                  to={createModuleUrl(node)}
                  onMouseEnter={() => {
                    hoverStart(node.id)
                  }}
                  onMouseLeave={() => {
                    hoverExit(node.id)
                  }}
                >
                  {node.title}
                </ModuleLink>
                <Bar />
              </Li>
            ))}
          </List>
        </FlexVerticalDrag>
      </ListContainer>
    </>
  )
}

export default ModuleList
